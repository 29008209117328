<template>
  <div class="zone">
    <TJDetailTitle :titleData="'用量统计_省份'" />
    <functionBox 
      :totalText="'总计：'+$tool.moneyFormatter(Number(sumPeople), 0)+'人份'"
      :titleData="{permission_flag:'Dosage',nowID:1}"
      :isExport="true"
      :searhLoading="loading"
      :exportLoading="exportLoading"
      @searchBtn="searchBtn"
      @exportFun="export_province_statsFun"
    >
      <template>
        <li>
          <ProvinceSelect @selectedProvince="handleSelectedProvince"></ProvinceSelect>

<!--          <el-select-->
<!--          v-model="province_id"-->
<!--          placeholder="请选择省份"-->
<!--          size="small"-->
<!--          filterable-->
<!--          clearable-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in options"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            ></el-option>-->
<!--          </el-select>-->
        </li>
        <li>
          <plat-select @platSelected="handlePlatSelect"></plat-select>
<!--          <el-select-->
<!--            v-model="platform_id"-->
<!--            placeholder="请选择平台"-->
<!--            size="small"-->
<!--            filterable-->
<!--            clearable-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in optionsProvince"-->
<!--              :key="item.platform"-->
<!--              :label="item.platform_unique"-->
<!--              :value="item.platform"-->
<!--            ></el-option>-->
<!--          </el-select>-->
        </li>
        <li>
          <timePicker @setTime="setTime"></timePicker>
        </li>
      </template>
    </functionBox>
    <!-- <div class="indent">
      <div class="indentone">
        <div class="left cursor">省份</div>
        <div class="line"></div>
        <div class="tohospital cursor" @click="tohospital">医院</div>
        <div class="line"></div>
        <div class="toDevice cursor" @click="toDevice">仪器</div>
        <p class="NumCla">总计：{{ $tool.moneyFormatter(Number(sumPeople), 0) }}人份</p>
      </div>
      <div class="below">
        <div class="city">
          
        </div>
        <div class="cityProvince">
          <el-select
            v-model="platform_id"
            placeholder="请选择平台"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsProvince"
              :key="item.platform"
              :label="item.platform_unique"
              :value="item.platform"
            ></el-option>
          </el-select>
        </div>
        <timePicker @setTime="setTime"></timePicker>
        <div class="seachone">
          <el-button type="primary" size="small" @click="searchBtn">
            搜索
          </el-button>
          <el-button
            class="exportExcle"
            type="success"
            size="small"
            @click="export_province_statsFun"
          >
            导出
          </el-button>
        </div>
      </div>
    </div> -->
    <div class="sheet">
      <el-table :data="tableData" stripe height="700" v-loading="loading">
        <el-table-column type="index" width="100"  label="序号" align="center">
          <template scope="scope">
            <span>{{serialtext(scope.$index)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="province_name" label="省份" align="center" />
        <el-table-column label="hs-CRP" align="center" >
          <template scope="scope">
              <span>{{ $tool.moneyFormatter(Number(scope.row.hs_crp),0)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="SAA" align="center" >
          <template scope="scope">
            <span>{{ $tool.moneyFormatter(Number(scope.row.saa),0)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="PCT" align="center" >
          <template scope="scope">
            <span>{{ $tool.moneyFormatter(Number(scope.row.pct),0)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="总计" align="center" >
          <template scope="scope">
            <span>{{ $tool.moneyFormatter(Number(scope.row.count_sum),0)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作 " width="120" align="center">
          <template slot-scope="scope">
            <div class="caozuo">
              <el-tooltip
                placement="left"
                effect="light"
                width="20"
                trigger="hover"
                content="构成分析"
                popper-class="custom-tooltip-border"
              >
                <div
                  class="box"
                  @click="toComposition(scope.row, $event)"
                >
                  <img
                    src="@/assets/img/Composition.png"
                    alt=""
                    @mouseenter="hoverC('lanse1', $event)"
                    @mouseleave="hoverC('Composition', $event)"
                  />
                </div>
              </el-tooltip>
              |
              <el-tooltip
                placement="left"
                effect="light"
                width="30"
                trigger="hover"
                content="时序分析"
                popper-class="custom-tooltip-border"
              >
                <div
                  class="box"
                  @click="toseries(scope.row, $event)"
                >
                  <img
                    src="@/assets/img/series.png"
                    alt=""
                    @mouseenter="hoverC('lanse2', $event)"
                    @mouseleave="hoverC('series', $event)"
                  />
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import timePicker from '@/components/timePicker'
import ProvinceSelect from '@/components/ProvinceSelect'
import functionBox from './components/functionBox.vue'
import { getdosage_nationwide } from '@/router/menuMain.js'
import PlatSelect from "@/components/PlatSelect.vue";

export default {
  name: 'Dosage',
  components: {PlatSelect, TJDetailTitle,timePicker,functionBox,ProvinceSelect },
  data() {
    return {
      province_id: '', //省份标识
      platform_id: '', //平台标识
      district_id: '', //地区选择-值
      radio: '年', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      dateMonthValue: '', //月份和日期选择
      page: 1,
      size: 10,
      total: 0,
      exportLoading: false,
      loading: false, //加载动画
      tableData: [],
      sumPeople: 0, //总计
      nationData: {}, //全国数据
      dosage_nationwide: false,
    }
  },
  computed: {
    serialtext() {
      return (index)=>{
        if(this.dosage_nationwide){
          if(index == 0) {
            return 0
          }else{
            return (this.page-1)*10+index
          }
        }else{
          return (this.page-1)*10+index+1
        }
      }
    },
  },
  created() {
    this.dosage_nationwide = getdosage_nationwide();
    
  },
  mounted() {
    // 延迟来让时间组件加载出来
    setTimeout(() => {
      this.get_data();
      // this.province_stats_sumFun()
    }, 200);
  },
  methods: {
    handlePlatSelect(val){
      this.platform_id = val
    },
    handleSelectedProvince(val){
      this.province_id = val
    },
    // 搜索按钮
    searchBtn() {
      this.page = 1
      // this.province_stats_sumFun()
      this.get_data();
    },
    //表格导出
    export_province_statsFun() {
      this.exportLoading = true
      let param = {
        province_id: this.province_id, //省份标识
        platform_id: this.platform_id, //平台标识
        ...this.filterQuery(),
      }
      api.export_province_stats(param).then((res) => {
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = 'imgName'
        a.href = res.data.file_url
        a.dispatchEvent(event)
      }).finally(msg => {
        this.exportLoading = false
      })
    },
    // 获取数据
    get_data() {
      this.loading = true

      if(this.dosage_nationwide){ //由全国先搜全国
        let param = {
          type: 'province',
          // ...this.filterQuery(),
          ...this.filterTime(),
        }
        api.national_usage_statistics(param).then(res => {
          if(res.code == 200){
            let national_data = res.data.national_data;
              for (const key in national_data) {
                let keyArr = key.split('__')
                if(keyArr.length == 2){
                  national_data[keyArr[0]] =  national_data[key]
                  delete national_data[key]
                }
              }
              national_data.count_sum = national_data.total
            this.national_data = national_data 
          }
        }).finally((msg) => {
          this.province_stats_sumFun();
        })
      }else{
        this.province_stats_sumFun()
      } 
    },
    // 列表
    province_stats_sumFun() {
      this.loading = true
      

      let param = {
        province_id: this.province_id, //省份标识
        platform_id: this.platform_id, //平台标识
        page: this.page,
        size: this.size,
        ...this.filterQuery(),
      }
      
      api
        .province_stats_sum(param)
        .then((res) => {
          if (res.code == 200) {
            this.loading = false
            let data = res.data.data
            // if(res.data.national_data){
            //   // 全国数据处理
            //   let national_data = res.data.national_data;
            //   for (const key in national_data) {
            //     let keyArr = key.split('__')
            //     if(keyArr.length == 2){
            //       national_data[keyArr[0]] =  national_data[key]
            //       delete national_data[key]
            //     }
            //   }
            //   national_data.count_sum = national_data.total
            //   data.unshift( national_data)
            // }
            if(this.national_data){
              data.unshift(this.national_data)
            }
            this.tableData = data
            this.total = res.data.count
            this.sumPeople = res.data.total
          } else {
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    
    // 根据回调修改当前页的相关属性
    setTime(val) {
      this[val.name] = val.value
    },
    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },

    // 翻页
    handleCurrentChange(val) {
      this.page = val
      this.province_stats_sumFun()
    },

    // 鼠标放在操作图标时
    hoverC(a, e) {
      e.target.src = require('@/assets/img/' + a + '.png')
    },

    tohospital() {
      this.$router.push({ path: '/DosageHospital' })
    },
    toDevice() {
      this.$router.push({ path: '/Device' })
    },

    // 跳转页面携带参数处理
    filterTime() {
      const date = new Date()
      let year = date.getFullYear(),
          month = date.getMonth() + 1,
          day = date.getDate()
      const current_date = `${year}-${month<10?'0'+month:month}-${day<10?'0'+day:day}`

      let param = {}
      let start_date = '',
          end_date = ''
      if (this.radio == '年') {
        start_date = this.start_stats_year+'0101'
        end_date = this.end_stats_year+'1231'
      } else if (this.radio == '月') {
        start_date = this.dateMonthValue[0]+'01'
        let tempData = new Date(this.dateMonthValue[1].slice(0,4),this.dateMonthValue[1].slice(4,6),0).getDate()
        end_date = this.dateMonthValue[1]+tempData
      } else if (this.radio == '日') {
        start_date = this.dateMonthValue[0]
        end_date = this.dateMonthValue[1]
      }
      end_date = `${end_date.slice(0,4)}-${end_date.slice(4,6)}-${end_date.slice(6,8)}`
      end_date = new Date(current_date)>new Date(end_date)?end_date.split('-').join(''):current_date.split('-').join('')
      
      return {
        start_stats_day: start_date,
        end_stats_day: end_date,
      }
    },
    // 跳转页面携带参数处理
    filterQuery() {
      let param = {}
      if (this.radio == '年') {
        param.start_stats_year = this.start_stats_year
        param.end_stats_year = this.end_stats_year
      } else if (this.radio == '月') {
        param.start_stats_month =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_month =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
      } else if (this.radio == '日') {
        param.start_stats_day =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_day =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
      }
      return param
    },
    // 构成分析
    toComposition(item, e) {
      this.hoverC('Composition', e)
      let query = {
        platform_id: this.platform_id, //平台标识
        province_id: item.province, //省份标识
        date_type: this.radio,
        ...this.filterQuery(),
      }
      if(item.province) {
        this.$router.push({ path: '/TimeSeries', query })
      }else{
        this.$router.push({ path: '/National-from', query })
      }
    },
    // 时序分析
    toseries(item, e) {
      this.hoverC('series', e)
      let query = {
        platform_id: this.platform_id, //平台标识
        province_id: item.province, //省份标识
        date_type: this.radio,
        ...this.filterQuery(),
      }
      if(item.province) {
       this.$router.push({ path: '/Analysis', query })
      }else{
       this.$router.push({ path: '/National-series', query })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.indent {
  //height: px(121);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
  .indentone {
    width: 100%;
    height: px(56);
    display: flex;
    border-bottom: 1px solid #d0d7e0;
    margin-bottom: px(15);

    .NumCla{
      justify-content: end;
      width: 80%;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
    .cursor {
      cursor: pointer;
    }
    .left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
    .toDevice {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }
    .tohospital {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }

    .line {
      width: 1px;
      height: 13px;
      background-color: rgba(208, 215, 224, 1);
      margin-top: px(16);
      margin-right: px(20);
    }
  }
  .below {
    display: flex;
    .calendar {
      margin-left: px(10);
    }
    .cityProvince {
      margin-left: px(10);
    }
    ::v-deep .cityProvince .el-input--small .el-input__inner {
      width: px(220);
    }
    .calendarone {
      display: flex;
      margin-left: px(10);
    }
    .seachone {
    margin-left: px(10);
    .exportExcle{
        margin-left: px(50);
      }
  }
  }
}
.sheet {
  min-height: px(793);
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);
  
  .el-table {
    .caozuo {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 40px;
      color: #d0d7e0;
      cursor: pointer;
      .box {
        width: 36px;
        height: 36px;
      }
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
</style>
<style>
.custom-tooltip-border{
  border: 1px solid #8f9297!important;
}
</style>